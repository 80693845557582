import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fisioterapia',
  templateUrl: './fisioterapia.component.html',
  styleUrls: ['./fisioterapia.component.scss']
})
export class FisioterapiaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
