import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements AfterContentInit {
  policies: any[] = [];
  constructor(private fireService: FirestoreService) { }

  ngAfterContentInit(): void {
    this.fireService.getPrices().subscribe((res:any[]): void => {
      for (let index = 0; index < res.length; index++) {
        if (res[index].payload.doc.data().active) {
          this.policies.push(res[index].payload.doc.data());
        }
      }
    });
  }
}
