<div class="col-md-12 section-heading text-center" style="margin-top: 5%">
  <h1><b>PRECIOS Y BONOS</b></h1>
</div>

<div id="fh5co-pricing" data-section="pricing">
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-4" *ngFor="let item of policies" >
          <div class="price-box">
            <h2 class="pricing-plan">{{ item.name }}</h2>
            <hr />
            <p>{{ item.desc }}</p>
            <div class="price">{{ item.price }} €</div>

            <hr />

            <a
              target="_blank"
              href="https://app.reasoningphysios.com//CitaOnline/citaDashboard.aspx?clinica=471&codigoImgLog=8011015345B6991C4DECC7B88678E89A467139B4"
              class="btn btn-default btn-sm"
              >Pide cita</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<shared-social></shared-social>

<!-- START Bootstrap-Cookie-Alert -->
<div class="alert text-center cookiealert" role="alert">
  Esta página web utiliza cookies. Si estas de acuerdo acepta y sigue navegando.
  <a href="cookies.html" target="_blank">Saber más</a>

  <button type="button" class="btn btn-primary btn-sm acceptcookies">
    Acepto
  </button>
</div>

<!-- Bootstrap -->
