<div class="row" id="social">
  <div class="col-md-12 text-center">
    <ul class="social social-circle">
      <h3 class="name">
        Ponte al día de nuestras novedades en Facebook o Instagram
      </h3>
      <br />

      <li>
        <a
          href="https://www.facebook.com/pages/category/Physical-Therapist/FisioActive-centro-de-fisioterapia-104370907813095/" target="_blank"
          ><img src="../../../assets/images/facebook.png">
        </a>
      </li>

      <li>
        <a href="https://www.instagram.com/fisioactive.castellon/" target="_blank"
          ><img src="../../../assets/images/instagram.png" >
        </a>
      </li>
    </ul>
  </div>
</div>
