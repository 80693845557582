import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  Images = [
    '../../assets/oldweb/images/home-bg.png',
    '../../assets/oldweb/images/f(1).jpg',
    '../../assets/oldweb/images/f(1).jpg',
  ];
  fireImages: any[] = [];
  arrowRight = faArrowRight;
  arrowLeft = faArrowLeft;

  constructor(private fireService: FirestoreService) {}

  ngOnInit(): void {
    const test = this.fireService.getSliderImages().subscribe (res => (this.fireImages = res));
    setTimeout(() => {
      console.log(this.fireImages);

    }, 2000);
  }
}
