import { Component, OnInit } from '@angular/core';
import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss'],
})
export class GaleriaComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[] = [];
  galleryImages: NgxGalleryImage[] = [];

  constructor() {}

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '100%',
        height: '800px',
        thumbnailsColumns: 5,
        thumbnailsSwipe: true,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true,
      },
    ];

    this.galleryImages = [

      {
        small: '../../assets/oldweb/images/_AMP7795.jpg',
        medium: '../../assets/oldweb/images/_AMP7795.jpg',
        big: '../../assets/oldweb/images/_AMP7795.jpg',
      },

      {
        small: '../../assets/oldweb/images/f (2).jpg',
        medium: '../../assets/oldweb/images/f (2).jpg',
        big: '../../assets/oldweb/images/f (2).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (18).jpg',
        medium: '../../assets/oldweb/images/f (18).jpg',
        big: '../../assets/oldweb/images/f (18).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (4).jpg',
        medium: '../../assets/oldweb/images/f (4).jpg',
        big: '../../assets/oldweb/images/f (4).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (6).jpg',
        medium: '../../assets/oldweb/images/f (6).jpg',
        big: '../../assets/oldweb/images/f (6).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (7).jpg',
        medium: '../../assets/oldweb/images/f (7).jpg',
        big: '../../assets/oldweb/images/f (7).jpg',
      },
      {
        small: '../../assets/oldweb/images/_AMP7736.jpg',
        medium: '../../assets/oldweb/images/_AMP7736.jpg',
        big: '../../assets/oldweb/images/_AMP7736.jpg',
      },
      {
        small: '../../assets/oldweb/images/f (8).jpg',
        medium: '../../assets/oldweb/images/f (8).jpg',
        big: '../../assets/oldweb/images/f (8).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (9).jpg',
        medium: '../../assets/oldweb/images/f (9).jpg',
        big: '../../assets/oldweb/images/f (9).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (11).jpg',
        medium: '../../assets/oldweb/images/f (11).jpg',
        big: '../../assets/oldweb/images/f (11).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (12).jpg',
        medium: '../../assets/oldweb/images/f (12).jpg',
        big: '../../assets/oldweb/images/f (12).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (13).jpg',
        medium: '../../assets/oldweb/images/f (13).jpg',
        big: '../../assets/oldweb/images/f (13).jpg',
      },
      {
        small: '../../assets/oldweb/images/_AMP7751.jpg',
        medium: '../../assets/oldweb/images/_AMP7751.jpg',
        big: '../../assets/oldweb/images/_AMP7751.jpg',
      },

      {
        small: '../../assets/oldweb/images/f (15).jpg',
        medium: '../../assets/oldweb/images/f (15).jpg',
        big: '../../assets/oldweb/images/f (15).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (16).jpg',
        medium: '../../assets/oldweb/images/f (16).jpg',
        big: '../../assets/oldweb/images/f (16).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (17).jpg',
        medium: '../../assets/oldweb/images/f (17).jpg',
        big: '../../assets/oldweb/images/f (17).jpg',
      },


      {
        small: '../../assets/oldweb/images/_AMP7801.jpg',
        medium: '../../assets/oldweb/images/_AMP7801.jpg',
        big: '../../assets/oldweb/images/_AMP7801.jpg',
      },
      {
        small: '../../assets/oldweb/images/_AMP7812.jpg',
        medium: '../../assets/oldweb/images/_AMP7812.jpg',
        big: '../../assets/oldweb/images/_AMP7812.jpg',
      },

      {
        small: '../../assets/oldweb/images/_AMP7778.jpg',
        medium: '../../assets/oldweb/images/_AMP7778.jpg',
        big: '../../assets/oldweb/images/_AMP7778.jpg',
      },
      {
        small: '../../assets/oldweb/images/_AMP7851.jpg',
        medium: '../../assets/oldweb/images/_AMP7851.jpg',
        big: '../../assets/oldweb/images/_AMP7851.jpg',
      },

      {
        small: '../../assets/oldweb/images/_AMP7587.jpg',
        medium: '../../assets/oldweb/images/_AMP7587.jpg',
        big: '../../assets/oldweb/images/_AMP7587.jpg',
      },
      {
        small: '../../assets/oldweb/images/f (19).jpg',
        medium: '../../assets/oldweb/images/f (19).jpg',
        big: '../../assets/oldweb/images/f (19).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (14).jpg',
        medium: '../../assets/oldweb/images/f (14).jpg',
        big: '../../assets/oldweb/images/f (14).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (10).jpg',
        medium: '../../assets/oldweb/images/f (10).jpg',
        big: '../../assets/oldweb/images/f (10).jpg',
      },
      {
        small: '../../assets/oldweb/images/f (3).jpg',
        medium: '../../assets/oldweb/images/f (3).jpg',
        big: '../../assets/oldweb/images/f (3).jpg',
      },

      {
        small: '../../assets/oldweb/images/f (5).jpg',
        medium: '../../assets/oldweb/images/f (5).jpg',
        big: '../../assets/oldweb/images/f (5).jpg',
      },

      
    ];
  }
}
