<nav class="navbar navbar-default navbar-expand-md navbar-dark" style="background-color: #047379;">
  <a href="#" class="navbar-brand"  style="margin-left: 8%; margin-top: 3px;">
      <img src="../../../assets/oldweb/images/logoblanco2.png" height="40" alt="Fisioactive">
  </a>
  <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
      <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav">

      </div>
      <div class="navbar-nav ml-auto">
        <ul class="navbar-nav">
          <li class="active">
            <a href="/" class="nav-item nav-link active" data-nav-section="home"
              ><span>Inicio</span></a
            >
          </li>
          <li class="dropdown">
            <a
              href="#"
              class="dropdown-toggle nav-item nav-link"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <span class="nav-label">Servicios</span>
              <span class="caret"></span
            ></a>
            <ul class="dropdown-menu">
              <li><a [routerLink]="['/fisioterapia']" class="nav-item nav-link">Fisioterapia Traumatológica</a></li>
              <li><a [routerLink]="['/fisioterapia/deportiva']" class="nav-item nav-link">Fisioterapia Deportiva</a></li>
              <li><a [routerLink]="['/suelo/pelvico']" class="nav-item nav-link">Suelo Pélvico</a></li>
              <li><a [routerLink]="['/linfedema']" class="nav-item nav-link">Linfedema</a></li>
              <li><a [routerLink]="['/fisioterapia/dermatofuncional']" class="nav-item nav-link">Fisioterapia Dermatofuncional</a></li>
            </ul>
          </li>

          <li>
            <a [routerLink]="['/galeria']"  class="nav-item nav-link"><span>Galería</span></a>
          </li>
          <li>
            <a [routerLink]="['/precios']" class="nav-item nav-link"><span>Precios y bonos</span></a>
          </li>
          <li>
            <a [routerLink]="['/contacto']" class="nav-item nav-link"><span>Contacto</span></a>
          </li>
          <li style="padding: .5rem 1rem;">
            <a  href="https://app.reasoningphysios.com//CitaOnline/citaDashboard.aspx?clinica=471&codigoImgLog=8011015345B6991C4DECC7B88678E89A467139B4" target="_blank" class="btn btn-dark btn-sm"><span>Pide cita</span></a>
          </li>
        </ul>
      </div>
  </div>
</nav>
