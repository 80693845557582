<!--<div id="slider" data-section="home">
  <div class="customPrevBtn">
    <fa-icon [icon]="arrowLeft" stackItemSize="2x"></fa-icon>
  </div>
  <div class="customNextBtn">
    <fa-icon [icon]="arrowRight" stackItemSize="2x"></fa-icon>
  </div>
  <div id="carousel" class="owl-carousel owl-carousel-fullwidth">
    <div class="item" id="slider1">
      <div class="overlay">
        <img
          class="sliderImg"
          src="../../assets/oldweb/images/f (24).jpg"
          alt="FisioActive Castellón"
        />
      </div>
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead ">Tu centro de fisioterapia</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" id="slider2">
      <div class="overlay">
        <img
          class="sliderImg"
          src="{{ fireImages[0].payload.doc.data().url }}"
          alt="{{ fireImages[0].payload.doc.data().name }}"
        />
      </div>
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead ">
                  {{ fireImages[0].payload.doc.data().name }}
                </h1>
                <h3 class="fh5co-sub-lead ">
                  {{ fireImages[0].payload.doc.data().text }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" id="slider2">
      <div class="overlay">
        <img
          class="sliderImg"
          src="{{ fireImages[1].payload.doc.data().url }}"
          alt="{{ fireImages[1].payload.doc.data().name }}"
        />
      </div>
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead ">
                  {{ fireImages[1].payload.doc.data().name }}
                </h1>
                <h3 class="fh5co-sub-lead ">
                  {{ fireImages[1].payload.doc.data().text }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" id="slider2">
      <div class="overlay">
        <img
          class="sliderImg"
          src="{{ fireImages[2].payload.doc.data().url }}"
          alt="{{ fireImages[2].payload.doc.data().name }}"
        />
      </div>
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead ">
                  {{ fireImages[2].payload.doc.data().name }}
                </h1>
                <h3 class="fh5co-sub-lead ">
                  {{ fireImages[2].payload.doc.data().text }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item" id="slider2">
      <div class="overlay">
        <img
          class="sliderImg"
          src="{{ fireImages[3].payload.doc.data().url }}"
          alt="{{ fireImages[3].payload.doc.data().name }}"
        />
      </div>
      <div class="container" style="position: relative">
        <div class="row">
          <div class="col-md-8 col-md-offset-2 text-center">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead ">
                  {{ fireImages[3].payload.doc.data().name }}
                </h1>
                <h3 class="fh5co-sub-lead ">
                  {{ fireImages[3].payload.doc.data().text }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->

<ngb-carousel
[pauseOnHover]="false"
>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img class="sliderImg" src="../../assets/oldweb/images/f (24).jpg" alt="test">
    </div>
    <div class="carousel-caption">
      <h3 style="color: white;"><b>Tu centro de fisioterapia</b></h3>
      <p></p>
    </div>
  </ng-template>
  <ng-template *ngFor="let slide of fireImages" ngbSlide>
    <div class="picsum-img-wrapper">
      <img class="sliderImg" src="{{ slide.payload.doc.data().url }}" alt="{{ slide.payload.doc.data().name }}">
    </div>
    <div class="carousel-caption">
      <h3 style="color: white;"><b>{{ slide.payload.doc.data().name }}</b></h3>
      <p>{{ slide.payload.doc.data().text }}</p>
    </div>
  </ng-template>
</ngb-carousel>

<div id="fh5co-about-us" data-section="about">
  <div class="container">
    <div class="row" id="about-us">
      <div class="col-md-12 section-heading text-center">
        <h2><b>Fisioactive - Centro de fisioterapia</b></h2>
        <div class="row justify-content-center">
          <div class="col-md-8 col-md-offset-2">
            <h3>
              En nuestro centro de fisioterapia, tenemos un único objetivo y
              este no puede ser otro que ofrecer el mejor servicio a nuestros
              pacientes, un tratamiento completo para mejorar su calidad de
              vida. En nuestras sesiones de fisioterapia no nos limitamos a
              seguir procedimientos previamente pautados, sino que optamos por
              una fisioterapia de calidad.
            </h3>
          </div>
        </div>
      </div>

      <div class="col-12 text-center">
        <h3 class=" mb-2">
          ¿QUÉ QUIERE DECIR FISIOTERAPIA DE CALIDAD?
        </h3>
        <ul class="list-group mb-3">
          <li class="list-group-item">
            Se realiza un tratamiento totalmente personalizado y a medida.
          </li>
          <li class="list-group-item">
            El fisioterapeuta estará completamente a disposición del paciente de
            forma ininterrumpida a lo largo de la sesión.
          </li>
          <li class="list-group-item">
            Las sesiones de fisioterapia serán de 50 minutos.
          </li>
          <li class="list-group-item">
            Se realizará un seguimiento del paciente y una reevaluación para ver
            la efectividad del tratamiento.
          </li>
          <li class="list-group-item">
            Terminaremos la sesión con pautas de ejercicios, estiramientos o
            consejos que complementan y refuerzan el tratamiento realizado en la
            sesión.
          </li>
        </ul>

        <p>
          <a class="btn btn-primary" data-toggle="collapse" data-target="#emoji"
            >Leer Más</a
          >
        </p>
      </div>
    </div>

    <div id="emoji" class="collapse">
      <div class="container-fluid padding">
        <!--- Two Column Section -->
        <div class="col-12"></div>

        <!--- Welcome Section -->

        <div class="container-fluid padding">
          <div class="row welcome text-center">
            <div class="col-12">
              <h3 class="display-5">
                PARA OFRECER EL MEJOR SERVICIO ESTAMOS EN CONSTANTE FORMACIÓN
              </h3>
              <br />
              <p>
                En nuestras sesiones de tratamiento combinamos distintas
                técnicas de terapia manual, fisioterapia invasiva y ecografía.
                Durante los 50 minutos de sesión estaremos a su entera
                disposición.
              </p>
            </div>
            <hr />
            <div class="col-12">
              <p class="lead">
                <b
                  >Algunas de las lesiones musculo-esqueléticas que tratamos
                  son:</b
                >
              </p>

              <ul class="list-group">
                <li class="list-group-item">
                  Dolor de espalda (cervicalgias, dorsalgias y lumbalgias)
                </li>
                <li class="list-group-item">Hernias y protusiones</li>
                <li class="list-group-item">
                  Tendinopatía del manguito rotador
                </li>

                <li class="list-group-item">Ciáticas</li>
                <li class="list-group-item">Esguinces</li>
                <li class="list-group-item">Epicondilitis</li>
                <li class="list-group-item">
                  Fasciosis plantar (con/sin espolón calcáneo)
                </li>
                <li class="list-group-item">Artrosis</li>
                <li class="list-group-item">
                  Rehabilitaciones postquirúrgicas
                </li>
                <li class="list-group-item">Fracturas óseas</li>
                <li class="list-group-item">Osteoporosis</li>
                <li class="list-group-item">Síndrome del túnel carpiano</li>
              </ul>

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" id="team">
      <div class="col-md-12 section-heading text-center ">
        <h2><b>Nuestras especializaciones</b></h2>
      </div>
      <div class="col-md-12">
        <div class="row row-bottom-padded-lg">
          <div class="col-md-4 text-center ">
            <div class="person">
              <img
                src="../../assets/oldweb/images/F 5.jpg"
                class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">Fisioterapia traumatológica:</h3>

              <p>
                La fisioterapia traumatológica consiste en la valoración, tratamiento y prevención de lesiones del sistema musculoesquelético, óseo y ligamentos, mediante medios manuales y físicos.

              </p>
            </div>
          </div>
          <div class="col-md-4 text-center ">
            <div class="person">
              <img
              src="../../assets/oldweb/images/F 17.jpg"
              class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">Fisioterapia deportiva:</h3>

              <p>
                La fisioterapia deportiva consiste en el tratamiento, prevención y readaptación de lesiones que no suelen aparecer en el día a día pero que sí que aparecen durante la práctica deportiva profesional o amateur. 

              </p>
            </div>
          </div>
          <div class="col-md-4 text-center ">
            <div class="person">
              <img
                src="../../assets/oldweb/images/_AMP7778.jpg"
                class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">Fisioterapia del suelo pélvico:</h3>

              <p>
                La fisioterapia del suelo pélvico consiste en valorar, tratar, mejorar y prevenir disfunciones que se
                producen en la zona del suelo pélvico.
              </p>
            </div>
          </div>
        </div>

        <div class="row row-bottom-padded-lg">
          <div class="col-md-6 text-center ">
            <div class="person">
              <img
              src="../../assets/oldweb/images/linfedema.jpg"
              class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">Fisioterapia en el linfedema:</h3>

              <p>
                La fisioterapia en el linfedema utiliza el drenaje linfático manual, el vendaje multicapa, la presoterapia y el ejercicio terapéutico para el tratamiento de esta patología.
              </p>
            </div>
          </div>
          <div class="col-md-6 text-center ">
            <div class="person">
              <img
                src="../../assets/oldweb/images/_AMP7848.jpg"
                class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">Fisioterapia dermatofuncional:</h3>

              <p>
                La fisioterapia dermatofuncional o fisioestética se encarga de la evaluación y tratamiento de trastornos
                producidos por otras patologías, procedimientos quirúrgicos y afectaciones del sistema tegumentario (la
                piel).
              </p>
            </div>
          </div>
          <!--<div class="col-md-4 text-center ">
            <div class="person">
              <img
                src="../../assets/oldweb/images/cascoimg.jpg"
                class="img-responsive img-rounded"
                alt="Person"
              />
              <h3 class="name">
                ESTIMULACIÓN TRANSCRANEAL POR CORRIENTE DIRECTA:
              </h3>

              <p>
                Consiste en aplicar una corriente sobre el cráneo para estimular
                áreas específicas del cerebro. Tiene gran aplicabilidad en el
                campo del dolor y trastornos motores.
              </p>
            </div>
          </div>
        </div>-->

        <div class="col-12 text-center">
         <!-- <p>
            <a href="#" [routerLink]="['/fisioterapia']" class="btn btn-primary">Saber Más</a>
          </p>-->

          <shared-social></shared-social>
        </div>
      </div>
    </div>
  </div>
</div>
