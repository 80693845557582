import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fisioterapia-dermatofuncional',
  templateUrl: './fisioterapia-dermatofuncional.component.html',
  styleUrls: ['./fisioterapia-dermatofuncional.component.scss']
})
export class FisioterapiaDermatofuncionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
