import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PricesComponent } from './prices/prices.component';

import { LocationStrategy, HashLocationStrategy} from '@angular/common';


import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SocialComponent } from './shared/social/social.component';
import { FisioterapiaComponent } from './fisioterapia/fisioterapia.component';
import { FisioterapiaDeportivaComponent } from './fisioterapia-deportiva/fisioterapia-deportiva.component';
import { SueloPelvicoComponent } from './suelo-pelvico/suelo-pelvico.component';
import { LinfedemaComponent } from './linfedema/linfedema.component';
import { FisioterapiaDermatofuncionalComponent } from './fisioterapia-dermatofuncional/fisioterapia-dermatofuncional.component';
import { ContactoComponent } from './contacto/contacto.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PrivacidadComponent } from './privacidad/privacidad.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const config = {
  apiKey: '<your-key>',
  authDomain: '<your-project-authdomain>',
  databaseURL: '<your-database-URL>',
  projectId: '<your-project-id>',
  storageBucket: '<your-storage-bucket>',
  messagingSenderId: '<your-messaging-sender-id>'
};

@NgModule({
  declarations: [
    AppComponent,
    PricesComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    SocialComponent,
    FisioterapiaComponent,
    FisioterapiaDeportivaComponent,
    FisioterapiaDermatofuncionalComponent,
    SueloPelvicoComponent,
    LinfedemaComponent,
    ContactoComponent,
    GaleriaComponent,
    AvisoLegalComponent,
    CookiesComponent,
    PrivacidadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FontAwesomeModule,
    NgbModule,
    NgxGalleryModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
