<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-12">
      <h2>Política de privacidad</h2>

      <br />
      <br />

      <h4 class="display-6">INFORMACIÓN BÁSICA DE PROTECCIÓN DE DATOS</h4>

      <br />
      <p></p>
      <ul>
        <li><b>Responsable: </b> ANTONIO MIÑANA MARÍN</li>
        <li>
          <b>Dirección: </b> Calle Santa María Rosa Molás, nº40-1º. Despacho C5.
          12004, Castellón de la Plana (Castellón), España
        </li>
        <li><b>Teléfono: </b>603033267</li>
        <li><b>Email: </b>fisioactivecastellon@gmail.com</li>
        <li><b>Página web: </b><a>http://www.fisioactivecastellon.com</a></li>
      </ul>
    </div>
  </div>
</div>

<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-12">
      <h4 class="display-6">
        INFORMACIÓN DETALLADA DE LA POLÍTICA DE PRIVACIDAD
      </h4>

      <br />
      <h4 class="display-6">1.- OBJETIVO DE LA POLÍTICA DE PRIVACIDAD</h4>
      <p>
        La presente "Política de Privacidad y Protección de Datos" tiene como
        nalidad dar a conocer las condiciones que rigen la recogida y
        tratamiento de los datos personales por parte de ANTONIO MIÑANA MARÍN,
        haciendo el máximo esfuerzo para velar por los derechos fundamentales,
        el honor y libertades de las personas de las que se tratan datos
        personales cumplimiento las normativas y leyes vigentes que regulan la
        Protección de Datos personales según la Unión Europea y el Estado
        Miembro español y, en concreto, las expresadas en el apartado
        "Actividades de Tratamientos" de esta Política de Privacidad. Por todo
        lo cual, en esta Política de Privacidad y Protección de datos, se
        informa a los usuarios del Website http://www .sioactivecastellon de
        todos los detalles de su interés respecto a cómo se realizan estos
        procesos, con qué nalidades, que otras entidades pudieran tener acceso a
        sus datos y cuáles son los derechos de los usuarios.
      </p>

      <h4 class="display-6">2.- DEFINICIONES</h4>
      <p>
        <b>«Datos personales»:</b> Toda información sobre una persona física
        identicada o identicable ("el usuario del Website"); se considerará
        persona física identicable toda persona cuya identidad pueda
        determinarse, directa o indirectamente, en particular mediante un
        identicador, como por ejemplo un nombre, un número de identicación,
        datos de localización, un identicador en línea o uno o varios elementos
        propios de la identidad física, siológica, genética, psíquica,
        económica, cultural o social de dicha persona.
      </p>
      <p>
        <b>«Tratamiento»:</b> cualquier operación o conjunto de operaciones
        realizadas sobre datos personales o conjuntos de datos personales, ya
        sea por procedimientos automatizados o no, como la recogida, registro,
        organización, estructuración, conservación, adaptación o modicación,
        extracción, consulta, utilización, comunicación por transmisión,
        difusión o cualquier otra forma de habilitación de acceso, cotejo o
        interconexión, limitación, supresión o destrucción.
      </p>
      <p>
        <b>«Limitación del tratamiento»:</b> el marcado de los datos de carácter
        personal conservados con el n de limitar su tratamiento en el futuro.
      </p>
      <p>
        <b>«Elaboración de perfiles»:</b> toda forma de tratamiento automatizado
        de datos personales consistente en utilizar datos personales para
        evaluar determinados aspectos personales de una persona física, en
        particular para analizar o predecir aspectos relativos al rendimiento
        profesional, situación económica, salud, preferencias personales,
        intereses, abilidad, comportamiento, ubicación o movimientos de dicha
        persona física.
      </p>
      <p>
        <b>«Seudonimización»:</b> el tratamiento de datos personales de manera
        tal que ya no puedan atribuirse a un interesado sin utilizar información
        adicional, siempre que dicha información adicional gure por separado y
        esté sujeta a medidas técnicas y organizativas destinadas a garantizar
        que los datos personales no se atribuyan a una persona física identicada
        o identicable.
      </p>
      <p>
        <b>«Fichero»:</b> todo conjunto estructurado de datos personales,
        accesibles con arreglo a criterios determinados, ya sea centralizado,
        descentralizado o repartido de forma funcional o geográca.
      </p>
      <p>
        <b>«Responsable del tratamiento» o «responsable» :</b> la persona física
        o jurídica, autoridad pública, servicio u otro organismo que, solo o
        junto con otros, determine los nes y medios del tratamiento; si el
        Derecho de la Unión o de los Estados miembros determina los nes y medios
        del tratamiento, el responsable del tratamiento o los criterios
        especícos para su nombramiento podrá establecerlos el Derecho de la
        Unión o de los Estados miembros.
      </p>
      <p>
        <b>«Encargado del tratamiento» o «encargado</b> la persona física o
        jurídica, autoridad pública, servicio u otro organismo que trate datos
        personales por cuenta del responsable del tratamiento.
      </p>
      <p>
        <b>«Destinatario»:</b> la persona física o jurídica, autoridad pública,
        servicio u otro organismo al que se comuniquen datos personales, se
        trate o no de un tercero. No obstante, no se considerarán destinatarios
        las autoridades públicas que puedan recibir datos personales en el marco
        de una investigación concreta de conformidad con el Derecho de la Unión
        o de los Estados miembros; el tratamiento de tales datos por dichas
        autoridades públicas será conforme con las normas en materia de
        protección de datos aplicables a los nes del tratamiento.
      </p>
      <p>
        <b>«Tercero»:</b>persona física o jurídica, autoridad pública, servicio
        u organismo distinto del interesado, del responsable del tratamiento,
        del encargado del tratamiento y de las personas autorizadas para tratar
        los datos personales bajo la autoridad directa del responsable o del
        encargado.
      </p>
      <p>
        <b>«Consentimiento del interesado»:</b>toda manifestación de voluntad
        libre, especíca, informada e inequívoca por la que el interesado acepta,
        ya sea mediante una declaración o una clara acción armativa, el
        tratamiento de datos personales que le conciernen.
      </p>
      <p>
        <b>«Violación de la seguridad de los datos personales»:</b>toda
        violación de la seguridad que ocasione la destrucción, pérdida o
        alteración accidental o ilícita de datos personales transmitidos,
        conservados o tratados de otra forma, o la comunicación o acceso no
        autorizados a dichos datos;
      </p>
      <p>
        <b>«Datos genéticos»:</b>datos personales relativos a las
        características genéticas heredadas o adquiridas de una persona física
        que proporcionen una información única sobre la siología o la salud de
        esa persona, obtenidos en particular del análisis de una muestra
        biológica de tal persona.
      </p>
      <p>
        <b>«Datos biométricos»:</b>datos personales relativos a las
        características genéticas heredadas o adquiridas de una persona física
        que proporcionen una información única sobre la siología o la salud de
        esa persona, obtenidos en particular del análisis de una muestra
        biológica de tal persona.
      </p>
      <p>
        <b>«Datos relativos a la salud»:</b>datos personales relativos a la
        salud física o mental de una persona física, incluida la prestación de
        servicios de atención sanitaria, que revelen información sobre su estado
        de salud.
      </p>
      <p>
        <b>«Establecimiento principal»:</b>a)en lo que se reere a un responsable
        del tratamiento con establecimientos en más de un Estado miembro, el
        lugar de su administración central en la Unión, salvo que las decisiones
        sobre los nes y los medios del tratamiento se tomen en otro
        establecimiento del responsable en la Unión y este último
        establecimiento tenga el poder de hacer aplicar tales decisiones, en
        cuyo caso el establecimiento que haya adoptado tales decisiones se
        considerará establecimiento principal; en lo que se reere a un encargado
        del tratamiento con establecimientos en más de un Estado miembro, el
        lugar de su administración central en la Unión o, si careciera de esta,
        el establecimiento del encargado en la Unión en el que se realicen las
        principales actividades de tratamiento en el contexto de las actividades
        de un establecimiento del encargado en la medida en que el encargado
        esté sujeto a obligaciones especícas con arreglo al presente Reglamento.
      </p>
      <p>
        <b>«Representante»:</b>persona física o jurídica establecida en la Unión
        que, habiendo sido designada por escrito por el responsable o el
        encargado del tratamiento con arreglo al artículo 27 del RGPD,
        represente al responsable o al encargado en lo que respecta a sus
        respectivas obligaciones en virtud del presente Reglamento.
      </p>
      <p>
        <b>«Empresa»:</b>persona física o jurídica dedicada a una actividad
        económica, independientemente de su forma jurídica, incluidas las
        sociedades o asociaciones que desempeñen regularmente una actividad
        económica.
      </p>
      <p>
        <b>«Autoridad de control»:</b>la autoridad pública independiente
        establecida por un Estado miembro con arreglo a lo dispuesto en el
        artículo 51 del RGPD. En el caso de España es la Agencia Española de
        Protección de Datos.
      </p>
      <p>
        <b>«Tratamiento transfronterizo»:</b><b>a)</b> el tratamiento de datos
        personales realizado en el contexto de las actividades de
        establecimientos en más de un Estado miembro de un responsable o un
        encargado del tratamiento en la Unión, si el responsable o el encargado
        está establecido en más de un Estado miembro, o <b>b)</b> el tratamiento
        de datos personales realizado en el contexto de las actividades de un
        único establecimiento de un responsable o un encargado del tratamiento
        en la Unión, pero que afecta sustancialmente o es probable que afecte
        sustancialmente a interesados en más de un Estado miembro
      </p>
      <p>
        <b>«Servicio de la sociedad de la información»:</b>todo servicio de la
        sociedad de la información, es decir, todo servicio prestado normalmente
        a cambio de una remuneración, a distancia, por vía electrónica y a
        petición individual de un destinatario de servicios.
      </p>

      <h4 class="display-6">3.- IDENTIDAD DEL RESPONSABLE DEL TRATAMIENTO</h4>
      <p>
        El Responsable del Tratamiento de Datos es aquella persona física o
        jurídica, de naturaleza pública o privada, u órgano administrativo, que
        solo o conjuntamente con otros determine los nes y medios del
        tratamiento de datos personales; en caso de que los nes y medios del
        tratamiento estén determinados por el Derecho de la Unión Europea o del
        Estado Miembro español. En los aspectos expresados en la presente
        Política de Privacidad y Protección de Datos, la identidad y datos de
        contacto del Responsable del Tratamiento es:
        <b>ANTONIO MIÑANA MARÍN - NIF/DNI 20921066J</b>
        Calle Santa María Rosa Molás, nº40-1º. Despacho C5. 12004, Castellón de
        la Plana (Castellón), España
        <b>Email:</b> fisioactivecastellon@gmail.com -
        <b>Teléfono:</b> 603033267
      </p>

      <h4 class="display-6">4.- LEYES Y NORMATIVAS APLICABLES</h4>
      <p>
        Esta Política de Privacidad y Protección de Datos está desarrollada en
        base a las siguientes normativas y leyes de protección de datos:
      </p>
      <ul class="list-group">
        <li class="list-group-item">
          Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
          de abril de 2016, relativo a la protección de las personas físicas en
          lo que respecta al tratamiento de datos personales y a la libre
          cierculación de estos datos. En adelante RGPD.
        </li>
        <li class="list-group-item">
          Ley Orgánica 3/2018, de 5 de diciembre de Protección de Datos
          Personales y Garantía de los Derechos Digitales. En adelante LOPD/GDD.
        </li>
        <li class="list-group-item">
          Ley 34/2002, de 11 de julio, de los Servicios de la Sociedad de la
          Información y el Comercio Electrónico. En adelante LSSICE.
        </li>
      </ul>
      <br />

      <h4 class="display-6">
        5.- PRINCIPIOS APLICABLES AL TRATAMIENTO DE DATOS PERSONALES
      </h4>
      <p>
        Los datos personales recogidos y tratados mediante este sitio Web, serán
        tratados de acuerdo con los siguientes principios:
      </p>
      <ul>
        <li>
          <b>Principio de licitud, lealtad y transparencia:</b> Todo tratamiento
          de datos personales realizado a través de este Website será lícito y
          leal, quedando totalmente claro para el usuario cuando se están
          recogiendo, utilizando, consultando o tratando los datos personales
          que le conciernen. La información relativa a los tratamientos
          realizados se transmitirá de forma previa, fácilmente accesible y
          fácil de entender, en un lenguaje sencillo y claro.
        </li>
        <li>
          <b>Principio de limitación de la nalidad:</b> Todos los datos serán
          recogidos con nes determinados, explícitos y legítimos, y no serán
          tratados posteriormente de manera incompatible con los nes para los
          que fueron recogidos.
        </li>
        <li>
          <b>Principio de minimización de datos:</b> Los datos recogidos serán
          adecuados, pertinentes y limitados a lo necesario en relación con los
          nes para los que son tratados.
        </li>
        <li>
          <b>Principio de exactitud:</b> Los datos serán exactos y, si fuera
          necesario, actualizados, adoptando todas las medidas razonables para
          que se supriman o rectiquen sin dilación los datos personales que sean
          inexactos con respecto a los nes para los que se tratan.
        </li>
        <li>
          <b>Principio de limitación del plazo de conservación:</b> Los datos
          serán mantenidos de forma que se permita la identicación de los
          interesados durante no más tiempo del necesario para los nes del
          tratamiento de los datos personales.
        </li>
        <li>
          <b>Principio de integridad y condencialidad:</b> Los datos serán
          tratados de manera que se garantice una seguridad adecuada de los
          datos personales, incluida la protección contra el tratamiento no
          autorizado o ilícito y contra su pérdida o daño accidenta, mediante la
          aplicación de medidas técnicas y organizativas apropiadas
        </li>
        <li>
          <b>Principio de responsabilidad proactiva:</b> La entidad propietaria
          del Website será responsable del cumplimiento de los principios
          expuestos en el presente apartado y será capaz de demostrarlo.
        </li>
      </ul>

      <h4 class="display-6">6.- ACTIVIDADES DE TRATAMIENTOS DE DATOS</h4>
      <p>
        A continuación, se detallan las actividades de tratamiento de datos
        realizadas mediante el sitio Web especicando cada uno de los siguientes
        apartados:
      </p>
      <ul>
        <li>
          <b>Actividad:</b> Nombre de la actividad de tratamiento de datos
        </li>
        <li>
          <b>Finalidades:</b> Cada uno de los usos y tratamientos que se
          realizan con los datos recabados
        </li>
        <li>
          <b>Base legal: </b> La base jurídica que legitima el tratamiento de
          los datos.
        </li>
        <li><b>Datos tratados:</b> Tipología de datos tratados</li>
        <li><b>Procedencia:</b> De dónde se obtienen los datos</li>
        <li>
          <b>Conservación: </b>Periodo durante el cual se conservan los datos
        </li>
        <li>
          <b>Destinatarios:</b>Personas o entidades terceras a las que se les
          facilitan los datos
        </li>
        <li>
          <b>Transferencias internacionales:</b> Envíos transfronterizos de los
          datos fuera de la Unión Europea
        </li>
      </ul>

      <h5 class="display-6">6.1 ACTIVIDADES DE TRATAMIENTO PRINCIPALES</h5>
      Son aquellas actividades de tratamientos de datos cuyas nalidades son
      necesarias e imprescindibles para la prestación de los servicios

      <h5 class="display-6">
        6.2 ACTIVIDADES DE TRATAMIENTO OPCIONALES (si el usuario ha marcado su
        aceptación)
      </h5>
      <p>
        Son aquellas actividades de tratamientos de datos personales cuyas
        nalidades no son imprescindibles para la prestación del servicio y que
        únicamente se llevan a cabo si el usuario ha marcado SI en el
        consentimiento para la realización de estas actividades.
      </p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">CONSULTAS WEBSITE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bases jurídicas</td>
            <td>Consentimiento explícito del interesado</td>
          </tr>
          <tr>
            <td>Finalidades</td>
            <td>
              Respuesta a consultas recibidas a través del formulario
              electrónico de la web
            </td>
          </tr>
          <tr>
            <td>Categorías de datos y colectivos</td>
            <td>Contactos web (Datos identicativos)</td>
          </tr>
          <tr>
            <td>Finalidades</td>
            <td>
              Respuesta a consultas recibidas a través del formulario
              electrónico de la web
            </td>
          </tr>
          <tr>
            <td>Procedencia de datos</td>
            <td>El propio interesado o su representante legal</td>
          </tr>
          <tr>
            <td>Categoría de destinatarios</td>
            <td>No están previstas</td>
          </tr>
          <tr>
            <td>Transferencia internacional</td>
            <td>No están previstas</td>
          </tr>
          <tr>
            <td>Plazos de conservación</td>
            <td>
              Durante un plazo de 1 año a partir de la última conrmación de
              interés
            </td>
          </tr>
        </tbody>
      </table>

      <h4 class="display-6">7.- INFORMACION NECESARIA Y ACTUALIZADA</h4>
      <p>
        Todos los campos que aparezcan señalados con un asterisco (*) en los
        formularios del Website serán de obligada cumplimentación, de tal modo
        que la omisión de alguno de ellos podría comportar la imposibilidad de
        que se le puedan facilitar los servicios o información solicitados.
        Deberá proporcionar información verídica, para que la información
        facilitada esté siempre actualizada y no contenga errores, deberá
        comunicar al Responsable del Tratamiento a la mayor brevedad posible,
        las modicaciones y recticaciones de sus datos de carácter personal que
        se vayan produciendo a través de un correo electrónico a la dirección:
        sioactivecastellon@gmail.com. Asimismo, al hacer “click” en el botón
        “Acepto” (o equivalente) incorporado en los citados formularios, declara
        que la información y los datos que en ellos ha facilitado son exactos y
        veraces, así como que entiende y acepta la presente Política de
        Privacidad.
      </p>

      <h4 class="display-6">8.- DATOS DE MENORES DE EDAD</h4>
      <p>
        En cumplimiento de lo establecido en el artículo 8 del RGPD y el
        artículo 7 de la LOPD/GDD, sólo lo mayores de 14 años de edad podrán
        otorgar su consentimiento para el tratamiento de sus datos personales de
        forma lítica por ANTONIO MIÑANA MARÍN. Por lo anterior, los menores de
        14 años de edad no podrán usar los servicios disponibles a través del
        Website sin la previa autorización de sus padres, tutores o
        representantes legales, quienes serán los únicos responsables de todos
        los actos realizados a través del Website por los menores a su cargo,
        incluyendo la cumplimentación de los formularios telemáticos con los
        datos personales de dichos menores y la marcación, en su caso, de las
        casillas que los acompañan.
      </p>

      <h4 class="display-6">
        9.- MEDIDAS DE SEGURIDAD TÉCNICAS Y ORGANIZATIVAS
      </h4>
      <p>
        El Responsable del Tratamiento adopta las medidas organizativas y
        técnicas necesarias para garantizar la seguridad y la privacidad de sus
        datos, evitar su alteración, pérdida, tratamiento o acceso no
        autorizado, dependiendo del estado de la tecnología, la naturaleza de
        los datos almacenado y los riesgos a que están expuestos.
      </p>
      <p>Entre otras, destacan las siguientes medidas:</p>

      <ul>
        <li>
          Garantizar la
          <b>condencialidad, integridad, disponibilidad y resiliencia</b>
          permanentes de los sistemas y servicios de tratamiento.
        </li>
        <li>
          Restaurar la <b>disponibilidad</b> y el <b>acceso</b> a los datos
          personales de forma rápida, en caso de incidente físico o técnico.
        </li>
        <li>
          Vericar, <b>evaluar y valorar,</b> de forma regular,
          <b>la ecacia de las medidas técnicas y organizativas</b> implementadas
          para garantizar la seguridad del tratamiento.
        </li>
        <li>
          <b>Seudonimizar y cifrar</b> los datos personales, en caso de que se
          trate de datos sensibles.
        </li>
      </ul>

      Por otro lado, el Responsable del Tratamiento ha tomado la decisión se
      gestionar los sistemas de la información de acuerdo a los siguientes
      principios:

      <ul>
        <li>
          <b>Principio de cumplimiento normativo:</b>Todos los sistemas de
          información se ajustarán a la normativa de aplicación legal
          regulatoria y sectorial que afecte a la seguridad de la información,
          en especial aquellas relacionadas con la protección de datos de
          carácter personal, seguridad de los sistemas, datos, comunicaciones y
          servicios electrónicos.
        </li>
        <li>
          <b>Principio de gestión de riesgo:</b>Se minimizaran los riesgos hasta
          niveles aceptables y buscar el equilibrio entre los controles de
          seguridad y la naturaleza de la información. Los objetivos de
          seguridad deberán ser establecidos, ser revisados y coherentes con los
          aspectos de seguridad de la información.
        </li>
        <li>
          <b>Principio de concienciación y formación</b>Se articularán programas
          de formación, sensibilización y campañas de concienciación para todos
          los usuarios con acceso a la información, en materia de seguridad de
          la información.
        </li>
        <li>
          <b>Principio de proporcionalidad:</b> La implantacion de controles que
          mitiguen los riesgos de seguridad de los activos se realizará buscando
          el equilibrio entre las medidas de seguridad, la naturaleza y la
          información y riesgo.
        </li>
        <li>
          <b>Principio de responsabilidad:</b>Todos los miembros del Responsable
          del Tratamiento, serán responsables de su conducta en cuanto a la
          seguridad de la información, cumpliendo con las normas y controles
          establecidos.
        </li>
        <li>
          <b>Principio de mejora contínua:</b>Se revisará de manera recurrente
          el grado de ecacia de los controles de seguridad implantados en la
          organización para aumentar la capacidad de adaptación a la constante
          evolución del riesgo y del entorno tecnológico.
        </li>
      </ul>
      <h4 class="display-6">10.- DERECHOS DE LOS INTERESADOS</h4>
      <p>
        La normativa vigente de protección de datos ampara al usuario en una
        serie de derechos en relación al uso que se dan a sus datos. Todos y
        cada uno de tales derechos son unipersonales e intransferibles, es
        decir, que únicamente pueden ser realizados por el titular de los datos,
        previa comprobación de su identidad.
      </p>
      <p>
        A continuación, se detallan cuáles son los derechos de los usuarios del
        Website:
      </p>

      <ul>
        <li>
          <b>Derecho de acceso:</b> Es el derecho que tiene el usuario del
          Website a obtener conrmación de si el Responsable del Tratamiento está
          tratando o no sus datos personales y, en caso armativo, obtener
          información sobre sus datos concretos de carácter personal y del
          tratamiento que el Responsable del Tratamiento haya realizado o
          realice, así como, entre otra, de la información disponible sobre el
          origen de dichos datos y los destinatarios de las comunicaciones
          realizadas o previstas en los mismos.
        </li>
        <li>
          <b>Derecho de recticación:</b>Es el derecho que el usuario del Website
          tiene a que se modiquen sus datos personales que resulten ser
          inexactos o, teniendo en cuenta los nes del tratamiento, incompletos.
        </li>
        <li>
          <b>Derecho de supresión</b>Suele conocerse como "derecho al olvido" ,
          y es el derecho que el usuario del Website tiene, siempre que la
          legislación vigente no establezca lo contrario, a obtener la supresión
          de sus datos personales cuando estos ya no sean necesarios para los
          nes para los cuales fueron recogidos o tratados; el Usuario haya
          retirado su consentimiento al tratamiento y este no cuente con otra
          base legal; el Usuario se oponga al tratamiento y no exista otro
          motivo legítimo para continuar con el mismo; los datos personales
          hayan sido tratados ilícitamente; los datos personales hayan sido
          obtenidos producto de una oferta directa de servicios de la sociedad
          de la información a un menor de 14 años. Además de suprimir los datos,
          el Responsable del Tratamiento, teniendo en cuenta la tecnología
          disponible y el coste de su aplicación, adoptará medidas razonables
          para informar a otros posibles responsables que estuvieren tratando
          los datos personales de la solicitud del interesado de supresión de
          cualquier enlace a esos datos personales.
        </li>
        <li>
          <b>Derecho a la limitación de los datos:</b>Es el derecho del Usuario
          del Website a limitar el tratamiento de sus datos personales. El
          Usuario del Website tiene derecho a obtener la limitación del
          tratamiento cuando impugne la exactitud de sus datos personales; el
          tratamiento sea ilícito; el Responsable del Tratamiento ya no necesite
          los datos personales, pero el Usuario lo necesite para hacer
          reclamaciones; y cuando el Usuario del Website se haya opuesto al
          tratamiento.
        </li>
        <li>
          <b>Derecho a la portabilidad de los datos</b>En aquellos casos que el
          tratamiento se efectúe por medios automatizados, el Usuario del
          Wesbsite tendrá derecho a recibir del Responsable del Tratamiento sus
          datos personales en un formato estructurado, de uso común y lectura
          mecánica, y a transmitirlos a otro responsable del tratamiento.
          siempre que se técnicamente posible, el Responsable del Tratamiento
          transmitirá directamente los datos a ese otro Responsable.
        </li>
        <li>
          <b
            >Derecho a no ser objeto de decisiones automatizadas y/o elaboración
            de perles</b
          >El el derecho del Usuario del Website a no ser objeto de una decisión
          individualizada basada únicamente en el tratamiento automatizado de
          sus datos personales, incluida la elaboración de perles, existente
          salvo que la legislación vigente establezca lo contrario.
        </li>
        <li>
          <b>Derecho a revocar el consentimiento:</b>Es el derecho del Usuario
          del Website a retirar, en cualquier momento, el consentimiento
          prestado para el tratamiento de sus datos.
        </li>
      </ul>

      El usuario del Website puede ejercer cualquiera de los derechos citados
      dirigiéndose al Responsable del Tratamiento y previa identicación del
      Usuario usando la siguiente información de contacto:
      <ul>
        <li><b>Responsable:</b> ANTONIO MIÑANA MARÍN</li>
        <li>
          <b>Dirección:</b> Calle Santa María Rosa Molás, nº40-1º. Despacho C5.
          12004, Castellón de la Plana (Castellón), España
        </li>
        <li><b>Teléfono:</b> 603033267</li>
        <li><b>E-mail:</b> fisioactivecastellon@gmail.com</li>
        <li><b>Página web: </b>http://www.fisioactivecastellon</li>
      </ul>

      <br />

      <h4 class="display-6">
        11.- DERECHO A RECLAMAR ANTE LA AUTORIDAD DE CONTROL
      </h4>
      <p>
        Se informa al usuario del su derecho a presentar una reclamación ante la
        Agencia Española de Protección de Datos si considera que se ha cometido
        una infracción de la legistlación en materia de protección de datos
        respecto al tratamiento de sus datos personales.
      </p>
      <p>Información de contacto de la autoridad de control:</p>
      <p>Agencia Española de Protección de Datos</p>
      <p><b>Email:</b> info@aepd.es</p>
      <p><b>Teléfono:</b> 912663517</p>
      <p><b>Sitio web:</b> https://www.aepd.es</p>
      <p><b>Dirección:</b> C/. Jorge Juan, 6. 28001, Madrid (Madrid), España</p>

      <h4 class="display-6">
        12.- ACEPTACIÓN Y CAMBIOS EN LA POLÍTICA DE PRIVACIDAD
      </h4>

      <p>
        Es necesario que el usuario del Website haya leído y esté conforme con
        las condiciones de protección de datos contenidas en esta Política de
        Privacidad, así como que acepte el tratamiento de sus datos personales
        para que el Responsable del Tratamiento pueda proceder al mismo en la
        forma, plazos y nalidades indicadas. El Responsable del Tratamiento se
        reserva el derecho a modicar la presente Política de Privacidad, de
        acuerdo a su propio criterio, o motivado por un cambio legislativo,
        jurisprudencial o doctrinal de la Agencia Española de Protección de
        Datos. Los cambios o actualizaciones realizados en esta Política de
        Privacidad que afecten a las nalidades, plazos de conservación, cesiones
        de datos a terceros, transferencias internacionales de datos, así como a
        cualquier derecho del Usuario del Website, serán comunicados de forma
        explícita al usuario.
      </p>
    </div>
  </div>
</div>

<hr class="my-4" />
