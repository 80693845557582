<div class="col-12"></div>
<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-md-12 justify-content-center text-center">
      <h1 class="display-5 text-center mt-5" id="title">
        <b>FISIOTERAPIA EN EL LINFEDEMA</b>
      </h1>

      <div class="row m-3">
        <div class="col-md-12">
          <p class="text-center" style="font-size: 20px !important; ">
            El linfedema es la acumulación progresiva de líquido a nivel intersticial que puede desarrollar una
            fibrosis, signo característico del linfedema crónico. En Fisioactive tratamos linfedemas post-oncológicos, post-quirúrgicos y muchos otros.
          </p>
        </div>

        <div class="col-md-6 mx-auto" style="margin-bottom: 40px;">
          <img src="../../assets/oldweb/images/linfedema.jpg" class="img-fluid" />
  
  
        </div>
        <!--<div class="col-md-6">
           <img src="../../assets/oldweb/images/F 20.jpg" class="img-fluid" />
           <img src="https://via.placeholder.com/150" alt="placeholder">
        </div>
        <div class="col-md-6">
          <img src="../../assets/oldweb/images/f4.0 18.jpg" class="img-fluid" />
          <img src="https://via.placeholder.com/150" alt="placeholder">
        </div>
      </div>-->

      <div class="col-md-12">
        <h4 class="display-6">
          <b>¿Qué signos y síntomas tiene el linfedema?</b>
        </h4>

        <h5 class="display-7"style="font-size: 20px !important; ">Hinchazón.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Sensación de pesadez.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Sensación de piel tirante.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Rigidez.</h5>
        <h5 class="display-7"  style="margin-bottom: 40px; font-size: 20px !important;">Endurecimiento y engrosamiento de la piel.</h5>
      </div>

      <div class="col-md-12">
        <h4 class="display-6">
          <b>¿Cómo podemos tratarlo?</b>
        </h4>

        <h5 class="display-7"style="font-size: 20px !important; ">Drenaje Linfático Manual.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Vendaje Compresivo Multicapa.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Presoterapia.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Vendaje Neuromuscular.</h5>
      </div>

      <!--  <a href="#" class="btn btn-primary">Descubre más</a> -->
    </div>
  </div>
</div>

<hr class="my-4" />