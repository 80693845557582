import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: AngularFirestore) {}

  //Obtiene un gato
  public async getCat(documentId: string) {
    return this.firestore.collection('prices').doc("10-ses").snapshotChanges();
  }
  //Obtiene todos los gatos
  public getPrices() {
    return this.firestore.collection('prices',ref => ref.orderBy('price')).snapshotChanges();
  }
  public getSliderImages() {
    return this.firestore.collection('slider').snapshotChanges();
  }
  //Actualiza un gato
  public updateCat(documentId: string, data: any) {
    return this.firestore.collection('cats').doc(documentId).set(data);
  }
}
