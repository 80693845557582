<!--- Footer -->
<footer id="footer" role="contentinfo">
  <div class="container-fluid padding">
    <div class="row text-center">
      <div class="col-md-4">
        <img src="../../../assets/oldweb/images/logoblanco.png" />
        <hr class="light" />

        <p>603 03 32 67</p>
        <p>fisioactivecastellon@gmail.com</p>
        <p>C/ Río Júcar, 45</p>
        <p>Castellón de la Plana, 12006</p>
      </div>
      <div class="col-md-4" id="nologo">
        <p>Horario</p>
        <hr class="light" />
        <p>Lunes - Viernes: 9:00 - 21:00</p>
        <p>Sábados: 9:00- 12:00</p>
      </div>
      <div class="col-md-4" id="nologo1">
        <p>Información adicional</p>
        <hr class="light" />
        <p><a [routerLink]="['/aviso-legal']" target="blank">Aviso Legal</a></p>
        <p><a [routerLink]="['/cookies']" target="blank">Política de Cookies</a></p>
        <p>
          <a [routerLink]="['/privacidad']" target="blank">Política de Privacidad</a>
        </p>
      </div>
      <br />
      <br />
      <div class="col-12">
        <hr class="light-100" />
        <h5>&copy; fisioactive.com</h5>
      </div>
    </div>
  </div>
</footer>
