import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fisioterapia-deportiva',
  templateUrl: './fisioterapia-deportiva.component.html',
  styleUrls: ['./fisioterapia-deportiva.component.scss']
})
export class FisioterapiaDeportivaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
