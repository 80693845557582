<div class="col-12"></div>
<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-md-12 justify-content-center text-center">
      <h1 class="display-5 text-center mt-5" id="title">
        <b>FISIOTERAPIA DEPORTIVA</b>
      </h1>

      <div class="row m-3">
        <div class="col-md-12">
          <p class="text-center" style="font-size: 20px !important; ">
            La fisioterapia deportiva consiste en el tratamiento, prevención y readaptación de lesiones que no suelen aparecer en el día a día pero que sí que aparecen durante la práctica deportiva profesional o amateur. Para realizar un buen tratamiento al deportista lo primero es conocer el deporte que practica, las lesiones más frecuentes y la biomecánica del mismo. Posteriormente se realizará el tratamiento específico para su lesión haciendo especial hincapié en en el gesto deportivo y mecanismo lesional para tratar de evitar las recaídas de la lesión.
          </p>
        </div>
        <div class="col-md-6">
          <img src="../../assets/oldweb/images/F 6.jpg" class="img-fluid" />
        </div>
        <div class="col-md-6">
          <img src="../../assets/oldweb/images/f (20).jpg" class="img-fluid" />
        </div>
      </div>

      <div class="col-md-12">
        <h4 class="display-6">
          <b>Entre las lesiones más comunes encontramos:</b>
        </h4>

        <h5 class="display-7"style="font-size: 20px !important; ">Tendinopatía en el hombro</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Tendinopatía rotuliana</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Tendinopatía aquilea</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Esguinces de tobillo</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Roturas fibrilares</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Contracturas musculares</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Codo de tenista o epicondilitis</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Codo de golfista o epitrocleitis</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Dolor y lesiones articulares</h5>
        <h5 class="display-7" style="margin-bottom: 40px; font-size: 20px !important;">Condromalacia rotuliana</h5>
      </div>

      <div class="col-md-12" >
        <h4 class="display-6">
          <b>Tratamientos:</b>
        </h4>

        <h5 class="display-7"style="font-size: 20px !important; ">Neuromodulación percutánea ecoguiada</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Electrólisis percutánea</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Ejercicio terapéutico</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Diatermia</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Neurodinámica</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Terapia manual</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Electroterapia</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Terapia miofascial</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Técnicas manipulativas</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Punción seca</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Kinesiotaping</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Vendaje funcional</h5>
      </div>

      <!--  <a href="#" class="btn btn-primary">Descubre más</a> -->
    </div>
  </div>
</div>

<hr class="my-4" />
