<div class="col-12"></div>
<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-md-12 justify-content-center text-center">
      <h1 class="display-5 text-center mt-5" id="title">
        <b>FISIOTERAPIA DERMATOFUNCIONAL</b>
      </h1>

      <div class="row m-3 mx-center">
        <div class="col-md-12">
          <p class="text-center" style="font-size: 20px !important; ">
            La fisioterapia dermatofuncional o fisioestética se encarga de la evaluación y tratamiento de trastornos
            producidos por otras patologías, procedimientos quirúrgicos y afectaciones del sistema tegumentario (la
            piel).
          </p>
        </div>
      
        
        <div class="col-lg-4">
        <img src="../../assets/oldweb/images/_AMP7778.jpg" class="img-fluid" />
        </div>
        <div class="col-lg-4">
        <img src="../../assets/oldweb/images/_AMP7851.jpg" class="img-fluid" />
      
        </div>
        <div class="col-lg-4">
          <img src="../../assets/oldweb/images/_AMP7795.jpg" class="img-fluid" />
        
          </div>
        
        
      </div>

      <div class="col-md-12">
        <h4 class="display-5">
          <b>¿Qué podemos tratar?</b>
        </h4>

        <h5 class="display-7"style="font-size: 20px !important; ">Cicatrices.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Flacidez en cara y cuerpo.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Efecto antiedad.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Acción reductora.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Celulitis.</h5>
        <h5 class="display-7" style="margin-bottom: 40px; font-size: 20px !important;">Problemas circulatorios debidos a un mal retorno venoso.</h5>
      </div>

      <div class="col-md-12">
        <h4 class="display-6">
          <b>¿Qué tecnología utilizamos?</b>
        </h4>

        <p style="font-size: 20px !important; ">Utilizamos la tecnología Winback para mejorar la capacidad del cuerpo para regenerarse. Tanto en los tratamientos corporales como faciales son suaves y agradables, proporcionando una sensación de relajación.</p>
      </div>

      <div class="col-sm-6 mx-auto">
        <img src="../../assets/oldweb/images/_AMP7586.jpg" class="img-fluid" />


      </div>

      <!--  <a href="#" class="btn btn-primary">Descubre más</a> -->
    </div>
  </div>
</div>

<hr class="my-4" />