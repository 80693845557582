<!--- Two Column Section -->
<div class="col-12"></div>
<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-12">
      <h2>POLITICA DE COOKIES</h2>

      <h4 class="display-6">1.- PROPIEDAD DEL SITIO WEB</h4>
      <br />

      <ul>
        <li><b>Responsable: </b> ANTONIO MIÑANA MARÍN</li>
        <li>
          <b>Dirección: </b> Calle Santa María Rosa Molás, nº40-1º. Despacho C5.
          12004, Castellón de la Plana (Castellón), España
        </li>
        <li><b>Contacto: </b>603033267</li>
        <li>
          <b>Registro mercantil o autorización administrativa:</b> Número de
          Colegiado: 6808
        </li>
        <li><b>Actividad:</b> Centro de Fisioterapia</li>
      </ul>

      <h4 class="display-6">2.- INFORMACIÓN Y  TERMINOLOGÍAS</h4>
      <p>
        Nuestro sitio Web http://www .sioactivecastellon (en adelante "Website")
        utiliza una tecnología denominada "cookies" con la nalidad de poder
        recabar información acerca del uso del Website. Las cookies son archivos
        pequeños que las páginas web, tiendas online, intranets, plataformas
        online o similares, almacenan en el navegador del usuario que las visita
        y resultan necesarios para aportar a la navegación web innumerables
        ventajas en la prestación de servicios interactivos.
      </p>

      <p>
        La siguiente información de los tipos posibles de cookies ayuda a
        comprender mejor las funciones que hacen posible:
      </p>

      <p>
        <b>Cookies de sesión: </b>
        Son cookies temporales que permanecen en el espacio de cookies de su
        equipo hasta que cierra el navegador, por lo que ninguna queda
        registrada en el disco del usuario. La información obtenida por medio de
        estas cookies sirve para hacer posible la gestión operativa con cada uno
        de los usuarios que está accediendo de forma simultánea a la web.
      </p>

      <p>
        <b>Cookies persistentes: </b>
        Son cookies que permanecen almacenadas en el espacio de cookies de su
        equipo una vez cerrado el navegador, y que volverá a consultar dicha
        página web la próxima vez que acceda a ella para recordar información
        que facilite la navegación (acceder directamente al servicio sin
        necesidad de hacer el proceso de login) o la prestación de un servicio
        comercial (ofrecer aquellos productos o servicios relacionados con
        anteriores visitas).
      </p>
      <p>
        Las cookies intercambiadas al navegar por una página web pueden ser:
      </p>
      <p>
        <b>Cookies de origen o propias: </b>

        Son cookies generadas por la propia página web que se está visitando.
      </p>

      <p>
        <b>Cookies de terceros: </b>

        Son cookies que se reciben al navegar por esa página web, pero que han
        sido generadas por un tercer servicio que se encuentra hospedado en
        ella. Un ejemplo puede ser la cookie empleada por un anuncio o banner de
        publicidad que se encuentra en la página web que visitamos. Otro puede
        ser la cookie empleada por un contador de visitantes contratado por la
        página web que visitamos.
      </p>

      <p>Las cookies pueden ser usadas para:</p>

      <p>
        <b>Fines técnicos: </b>
        Son las denominadas también “estrictamente necesarias” . Permiten al
        usuario la navegación a través de una página web, plataforma o
        aplicación y la utilización de las diferentes opciones o servicios que
        en ella existan como, por ejemplo, controlar el tráco y la comunicación
        de datos, identicar la sesión, acceder a partes de acceso restringido,
        recordar los elementos que integran un pedido, realizar el proceso de
        compra de un pedido, realizar la solicitud de inscripción o
        participación en un evento, utilizar elementos de seguridad durante la
        navegación, almacenar contenidos para la difusión de videos o sonido o
        compartir contenidos a través de redes sociales.
      </p>

      <p>
        <b>Personalización: </b>
        Hacen posible que cada usuario pueda congurar aspectos como el lenguaje
        en el que desea ver la página web, formatos de visualización, etc.
      </p>

      <p>
        <b>Análisis o rendimiento: </b>

        Permiten medir el número de visitas y criterios de navegación de
        diferentes áreas de la web, aplicación o plataforma y nos permite
        elaborar perles de navegación de los usuarios de dichos sitios,
        aplicaciones y plataformas, con el n de introducir mejoras en función
        del análisis de los datos de uso recabados que hacen los usuarios del
        servicio.
      </p>

      <p>
        <b>Publicidad comportamental: </b>
        Permiten implementar parámetros de eciencia en la publicidad ofrecida en
        las páginas web, basados en información sobre el comportamiento de los
        usuarios obtenido a través de la observación continuada de sus hábitos
        de navegación, lo que permite desarrollar un perl especíco para mostrar
        publicidad en función del mismo.
      </p>

      <p>
        <b>Publicidad: </b>

        Permiten implementar parámetros de eciencia en la publicidad ofrecida en
        las páginas web.
      </p>

      <h4 class="display-6">4.- SERVICIOS DE TERCEROS</h4>
      <p>
        Adicionalmente, FISIOACTIVE CENTRO FISIOTERAPIA pudiera tener presencia
        en portales y servicios de terceros para los que, si desea conocer las
        condiciones de privacidad y uso de cookies, deberán consultarse las
        políticas proporcionadas por los mismos. A título enunciativo y sin
        carácter exclusivo, se enumeran algunos de tales servicios de tercersos
        y dónde consultar su política de privacidad. ANTONIO MIÑANA MARÍN no se
        hace responsable del correcto funcionamiento de los enlaces a las
        diferentes políticas de privacidad y uso de cookies por tercersos debido
        a que ANTONIO MIÑANA MARÍN no puede controlar cúando son modicados por
        el proveedor, por lo que será el Usuario el responsable de averiguar los
        contenidos actualizados en las respectivas políticas de privacidad y de
        cookies de dichos terceros.
      </p>

      <ul>
        <li>
          <b>Facebook: </b>
          <a href="https://es-es.facebook.com/help/cookies"
            >https://es-es.facebook.com/help/cookies
          </a>
        </li>
        <li>
          <b>Twitter: </b>
          <a href="https://twitter.com/privacy ">
            https://twitter.com/privacy
          </a>
        </li>
        <li>
          <b>Youtube: </b>
          <a
            href=" https://www.google.es/intl/es/policies/technologies/cookies/"
            > https://www .google.es/intl/es/policies/technologies/cookies/</a
          >
        </li>
        <li>
          <b>Linkedin: </b>
          <a href="http://www.linkedin.com/legal/privacy-policy"
            >http://www.linkedin.com/legal/privacy-policy</a
          >
        </li>
        <li>
          <b>Instagram: </b>
          <a href="https://www.facebook.com/policies/cookies/"
            >https://www.facebook.com/policies/cookies/
          </a>
        </li>
      </ul>

      <h4 class="display-6">5.- ACEPTACIÓN DE LA POLÍTICA DE COOKIES</h4>

      <p>
        FISIOACTIVE CENTRO FISIOTERAPIA muestra información sobre su Política de
        Cookie en la parte inferior de cualquier página del sitio Web con cada
        inicio de sesión con el objeto de que usted sea consciente
      </p>

      <p>
        Ante esta información es posible llevar a cabo las siguientes acciones:
      </p>

      <p>
        <b>Aceptar las cookies:</b>
        No se volverá a visualizar este aviso al acceder a cualquier página del
        portal durante la presente sesión, y el Usuario estará consintiendo el
        uso de las cookies enunciadas en esta Política de Cookies, por los
        plazos señalados y en las condiciones establecidas.
      </p>

      <p>
        <b> Modicar la conguración: </b>

        Podrá obtener más información sobre cómo son las cookies, conocer la
        Política de Cookies de FISIOACTIVE CENTRO FISIOTERAPIA y modicar la
        conguración de su navegador para restringir o bloquear las cookies
        de FISIOACTIVE CENTRO FISIOTERAPIA en cualquier momento. En el caso de
        restringir o bloquear las cookies puede ver reducidas las
        funcionalidades de la Web.
      </p>

      <p>
        <b>Seguir navegando o moverse por la barra de desplazamiento: </b>

        En cuyo caso consideramos que acepta su uso.
      </p>

      <h4 class="display-6">6.- MODIFICACIÓN Y BLOQUEO DE COOKIES</h4>

      <p>
        El usuario puede restringir, bloquear o borrar las cookies de
        FISIOACTIVE CENTRO FISIOTERAPIA o cualquier otra página web, utilizando
        su navegador. En cada navegador la operativa es diferente, la función de
        "Ayuda" de cada navegador mostrará al Usuario cómo hacerlo.
      </p>

      <p>A título enunciativo, se facilitan los siguientes enlaces de ayuda:</p>

      <ul>
        <li>
          <b>Internet Explorer:</b>
          <a
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </li>
        <li>
          <b>Microsoft Edge:</b>
          <a
            href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoftprivacy "
          >
            https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoftprivacy
          </a>
        </li>
         
        <li>
          <b>FireFox:</b>
          <a
            href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias"
            >https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a
          >
        </li>
        <li>
          <b>Chrome: </b
          ><a
            href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es"
            >https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a
          >
        </li>
        <li>
          <b>Safari: </b
          ><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
            >https://support.apple.com/es-es/guide/safari/sfri11471/mac</a
          >
        </li>
      </ul>

      <p>
        Además, también puede gestionar el almacén de cookies en su navegador a
        través de herramientas como las siguientes:
      </p>
      <li>
        <b>Ghostery: </b><a href="www.ghostery.com/">www.ghostery.com/</a>
      </li>
      <li>
        <b>Your online choices:</b
        ><a href="www.youronlinechoices.com/es/ "
          >www.youronlinechoices.com/es/
        </a>
      </li>

       
      <h4 class="display-6">7.- CAMBIOS EN LA POLÍTICA DE COOKIES</h4>
       

      <p>
        Es posible que actualicemos la Política de Cookies de nuestro Sitio Web.
        Por ello, se recomienda al Usuario del Website que revisa esta política
        cada vez que acceda a nuestro Sitio Web con el objetivo de estar
        adecuadamente informado sobre cómo y para qué usamos las cookies
      </p>
    </div>
  </div>
</div>
