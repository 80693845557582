<div class="text-center">
  <!--Section heading-->
  <h1 class="h1-responsive font-weight-bold text-center my-4">
    <b>CONTÁCTANOS</b>
  </h1>
  <!--Section description-->
  <p class="text-center w-responsive mx-auto mb-5">
    <b>¿Tienes alguna pregunta? fisioactivecastellon@gmail.com</b>
  </p>
</div>

<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-12">
      <!--The div element for the map -->
      <div style="width:100%;">
        <div class="gmap_canvas">
          <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12229.494366036015!2d-0.0574452!3d39.9777558!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd5fffea33c0eaab%3A0x408b071df5476f74!2sFISIOACTIVE%20Centro%20de%20Fisioterapia!5e0!3m2!1ses!2ses!4v1695936501834!5m2!1ses!2ses" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
<shared-social></shared-social>
