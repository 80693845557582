
         <div class="col-12"></div>
         <div class="container-fluid padding">
           <div class="row padding">
             <div class="col-md-12 justify-content-center text-center">
               <h1 class="display-5 text-center mt-5" id="title">
                 <b>FISIOTERAPIA TRAUMATOLÓGICA</b>
               </h1>
         
               <div class="row m-3">
                 <div class="col-md-12">
                   <p class="text-center" style="font-size: 20px !important; ">
                    La fisioterapia traumatológica consiste en la valoración, tratamiento y prevención de lesiones del sistema musculoesquelético, óseo y ligamentos, mediante medios manuales y físicos.
                  </p>
                 </div>
                 <div class="col-sm-6">
                   <img src="../../assets/oldweb/images/f4.0 2.jpg" class="img-fluid" />
                 </div>
                 <div class="col-sm-6">
                   <img src="../../assets/oldweb/images/F 17.jpg" class="img-fluid" />
                 </div>
               </div>
         
               <div class="col-md-12">
                 <h4 class="display-6">
                   <b>¿Qué patologías podemos encontrarnos?
                  </b>
                 </h4>
                 <div style="font-size: 20px !important; ">
                    <h5 class="display-7"style="font-size: 20px !important; ">Fracturas.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Politraumatismos.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Esguinces.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Luxaciones.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Artrosis.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Tendinopatias</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Osteoporosis</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Fibromialgia.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Dolor crónico.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Hernias y protusiones.</h5>
                    <h5 class="display-7"style="font-size: 20px !important; ">Ciáticas.</h5>
                    <h5 class="display-7" style="margin-bottom: 40px; font-size: 20px !important;">¡¡Y MUCHO MÁS!!</h5>
                </div>
               </div>
               
         
               <div class="col-md-12" >
                 <h4 class="display-6">
                   <b>Tratamientos:</b>
                 </h4>
                 <div style="font-size: 20px !important; ">

                 <h5 class="display-7" style="font-size: 20px !important; ">Neuromodulación percutánea ecoguiada</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Estimulación transcraneal por corriente directa</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Electrólisis percutánea</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Magnetoterapia</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Diatermia</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Neurodinámica</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Terapia manual</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Electroterapia</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Terapia miofascial</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Técnicas manipulativas</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Punción seca</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Kinesiotaping</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Vendaje funcional</h5>
                 <h5 class="display-7" style="font-size: 20px !important; ">Ejercicio terapéutico</h5>

                 </div>

               </div>
         
               <!--  <a href="#" class="btn btn-primary">Descubre más</a> -->
             </div>
           </div>
         </div>
         
         <hr class="my-4" />
         
         
         
         <!--<img src="../../assets/oldweb/images/F 6.jpg" class="img-fluid" />-->

                    <!--<img src="../../assets/oldweb/images/f4.0 16.jpg" class="img-fluid" />-->
         <!-- <img src="../../assets/oldweb/images/f4.0 2.jpg" class="img-fluid" />-->
          <!--<img src="../../assets/oldweb/images/F 14.jpg" class="img-fluid" />-->
          <!--<img src="../../assets/oldweb/images/F 12.jpg" class="img-fluid" />-->
