<div class="col-12"></div>
<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-md-12 justify-content-center text-center">
      <h1 class="display-5 text-center mt-5" id="title">
        <b>FISIOTERAPIA DEL SUELO PÉLVICO</b>
      </h1>

      <div class="col-md-12">
        <p class="text-center" style="font-size: 20px !important; "> El suelo pélvico es un conjunto de músculos, ligamentos, fascias, articulaciones y
          huesos que se encuentran en la base de la pelvis de mujeres y hombres. El suelo pélvico tiene diferentes
          funciones, como pueden ser: sostén visceral,
          mantiene la continencia urinaria, de gases y fecal,
          actúa en las relaciones sexuales, interviene en la micción, defecación y parto, tiene la función de
          estabilidad lumbo-pélvica y cierra la pared inferior de la cavidad abdominal y pelvis ósea.
        </p>
      </div>

      <!--<div class="col-md-6 mx-auto">
        <img src="../../assets/oldweb/images/_AMP7853.jpg" class="img-fluid" />


      </div>-->

      <div class="row m-3">
        <div class="col-md-12">
          <p class="text-center" style="font-size: 20px !important;">

            La fisioterapia del suelo pélvico consiste en valorar, tratar, mejorar y prevenir disfunciones que se
            producen en la zona del suelo pélvico.

          </p>
        </div>
        <div class="col-md-6">
          <img src="../../assets/oldweb/images/_AMP7736.jpg" class="img-fluid" />
          


        </div>

        <div class="col-md-6">
          <img src="../../assets/oldweb/images/_AMP7812.jpg" class="img-fluid" />
          
        </div>
      </div>

      <div class="col-md-12">
        <h4 class="display-6">
          <b>¿En qué te podemos ayudar?</b>
        </h4>
        <h5 class="display-7"style="font-size: 20px !important; ">Diástasis abdominal</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Episiotomías y desgarros</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Prolapsos.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Incontinencia urinaria.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Incontinencia fecal o de gases.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Dolor durante la penetración.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Anorgasmia o dificultades para llegar al orgasmo.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Dolor Pélvico Crónico.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Dolor menstrual.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Embarazo.</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Postparto.</h5>
        <h5 class="display-7" style="margin-bottom: 40px; font-size: 20px !important; ">¡¡Y MUCHO MÁS!!</h5>
      </div>

      <div class="col-md-12">
        <h4 class="display-6">
          <b>Tratamientos:</b>
        </h4>
        <h5 class="display-7"style="font-size: 20px !important; ">Radiofrecuencia Winback &reg;</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Ecografía funcional</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Biofeedback / Electroestimulación NeuroTrac &reg;</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Punción Seca</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Inducción miofascial</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Terapia manual</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Vendaje neuromuscular</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Drenaje linfático manual</h5>
        <h5 class="display-7"style="font-size: 20px !important; ">Presoterapia</h5>
      </div>

      <div class="col-md-6 mx-auto">
        <img src="../../assets/oldweb/images/_AMP7751.jpg" class="img-fluid" />


      </div>
      <!--  <a href="#" class="btn btn-primary">Descubre más</a> -->
    </div>
  </div>
</div>

<hr class="my-4" />