import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linfedema',
  templateUrl: './linfedema.component.html',
  styleUrls: ['./linfedema.component.scss']
})
export class LinfedemaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
