<div class="container-fluid padding">
  <div class="row padding">
    <div class="col-12">
      <h2>AVISO LEGAL WEB</h2>

      <h4 class="display-6">1.- INFORMACIÓN LEGAL Y ACEPTACIÓN</h4>
      <p>
        En cumplimiento con lo establecido en la Ley 34/2002 de 11 de Julio, de
        Servicios de la Sociedad de la Información y el Comercio Electrónico
        (LSSICE), las presentes disposiciones regulan el uso del servicio del
        Website http://www .sioactivecastellon, (en adelante "Website") que
        ANTONIO MIÑANA MARÍN pone a disposición de los usuarios de Internet. El
        acceso al Website es gratuito salvo en lo relativo al coste de la
        conexión a través de la rede de telecomunicaciones suministrada por el
        proveedor de acceso contratado por los usuarios. Determinados servicios
        son exclusivos para nuestros clientes y su acceso se encuentra
        restringido. Los servicios prestados por ANTONIO MIÑANA MARÍN con
        carácter general tienen el precio que se indique en la correspondiente
        propuesta de servicios y en ningún caso se ofrecen o prestan a través
        del servicio del portal. En aquellos casos y secciones que nuestro
        Website ofrezca servicios de Comercio Electrónico sujeto a contratación
        o contraprestración económica, se deberán aceptar las Condiciones
        Generales de Contratación especicadas en su caso. La utilización del
        Website atribuye la condición de usuario del Website (en adelante,
        Usuario) e implica la aceptación de todas las condiciones incluidas en
        este Aviso Legal. La prestación del servicio del Website tiene una
        duración limitada al momento en que el Usuario se encuentre conectado al
        Website o a alguno de los servicios que a través del mismos se
        facilitan. Por tanto, el Usuario debe leer atentamente el presente Aviso
        Legal en cada una de las ocasiones en que se proponga utilizar el
        Website, ya que éste y sus condiciones de uso recogidas en el presente
        Aviso Legal pudieran sufrir modicaciones. Algunos servicios del Website
        accesibles para los usuarios de Internet o excluisivos para los clientes
        de ANTONIO MIÑANA MARÍN pueden estar sometidos a condiciones
        particulares, reglamento e instrucciones que, en su caso, sustituyen,
        completan y/o modican el presente Aviso Legal y que deberán ser
        aceptadas por el Usuario antes de iniciarse la prestación de los
        servicios correspondientes.
      </p>

      <br />
      <br />

      <h4 class="display-6">2.- IDENTIFICACIÓN DEL PROPIETARIO DEL WEBSITE</h4>

      <br />
      <p></p>
      <ul>
        <li><b>Titular del Website: </b> ANTONIO MIÑANA MARÍN</li>
        <li>
          <b>Dirección: </b> Calle Santa María Rosa Molás, nº40-1º. Despacho C5.
          12004, Castellón de la Plana (Castellón), España
        </li>
        <li><b>Contacto: </b>603033267</li>
        <li>
          <b>Registro mercantil o autorización administrativa:</b> Número de
          Colegiado: 6808
        </li>
        <li><b>Actividad:</b> Centro de Fisioterapia</li>
      </ul>

      <h4 class="display-6">3.- PROPIEDAD INTELECTUAL, INDUSTRIAL Y FRAMES</h4>
      <p>
        Todos los elementos que forman el sitio Web
        (http://www.fisioactivecastellon), entendiendo por estos a título
        meramente enunciativo, los textos, fotografías, grácos, imágenes,
        iconos, tecnología, softwares, links y demás contenidos audiovisuales o
        sonoros, así como su estructura, diseño gráco, códigos fuente, así como
        los logos, marcas y demás signos distintivos que aparecen en el mismo,
        son titularidad de FISIOACTIVE CENTRO FISIOTERAPIA o de sus
        colaboradores y están protegidos por los correspondientes derechos de
        propiedad intelectual e industrial,  El titular del sitio Web
        (FISIOACTIVE CENTRO FISIOTERAPIA) prohíbe expresamente la realización de
        "framing" o la utilización por parte del Usuario o de terceros de
        cualesquiera otros mecanismos que alteren el diseño, conguración
        original o contenidos de nuestro sitio Web. El uso de los contenidos
        deberá respetar su licenciamiento particular. De tal modo su uso,
        reproducción, distribución, comunicación pública, transformación o
        cualquier otra actividad similar o análoga, queda totalmente prohibida
        salvo que medie previa y expresa autorización del titular del sitio
        Web, FISIOACTIVE CENTRO FISIOTERAPIA. Únicamente se autoriza la
        reproducción total o parcial de los textos y contenidos proporcionados
        por el sitio Web, siempre que concurran todas y cada una de las
        siguientes condiciones:
      </p>

      <ul>
        <li>
          Se mantenga la integridad de los contenidos, documentos o grácos
        </li>
        <li>
          Se cite expresamente al titular del sitio Web como fuente y origen de
          aquellos.
        </li>
        <li>
          El propósito y la nalidad de tal uso sea compatible con los nes de la
          Web y/o actividad del titular del sitio Web.
        </li>
        <li>
          No se pretenda un uso comercial, quedando expresamente prohibidas su
          distribución, comunicación pública, transformación o descompilación
        </li>
      </ul>

      Cualquier otro uso habrá de ser comunicado y autorizado por el titular del
      sitio Web, previa y expresamente. Respecto a las citas de productos y
      servicios de terceros, FISIOACTIVE CENTRO FISIOTERAPIA reconoce a favor de
      sus titulares los correspondientes derechos de propiedad industrial e
      intelectual, no implicando su sola mención o aparición en la Web la
      existencia de derechos ni de responsabilidad alguna sobre los mismos, como
      tampoco respaldo, patrocinio o recomendación. FISIOACTIVE CENTRO
      FISIOTERAPIA declara su respeto a los derechos de propiedad intelectual e
      industrial de terceros; por ello, si considera que el sitio Web http://www
      .sioactivecastellon pudieran estar violando sus derechos, rogamos se ponga
      en contacto con FISIOACTIVE CENTRO FISIOTERAPI

      <h4 class="display-6">4.- CONDICIONES GENERALES DE USO</h4>
      <p>
        El portal web http://www .sioactivecastellon tiene como objeto facilitar
        al público, en general, el conocimiento de las actividades que esta
        organización realiza y de los productos y servicios que presta, para el
        desarrollo de su actividad. El uso de cualquiera de las funcionalidades
        del sitio Web implica la expresa y plena aceptación de las condiciones
        aquí expuestas, sin perjuicio de aquellas particulares que pudieran
        aplicarse a algunos de los servicios concretos ofrecidos a través del
        sitio Web. El titular del sitio Web se reserva la facultad de efectuar,
        en cualquier momento y sin necesidad de previo aviso, modicaciones y
        actualizaciones de la información contenida en su Web o en la
        conguración y presentación de ésta. Con el objeto de mantener
        actualizada la información publicada el portal Web, los contenidos del
        mismo podrán ser modicados, corregidos, eliminados o añadidos en
        cualquier momento, por lo que será conveniente comprobar la vigencia o
        exactitud de los mismos acudiendo a las fuentes ociales.
      </p>

      <h4 class="display-6">5.- EXCLUSIÓN DE RESPONSABILIDAD</h4>

      <p>
        <b>5.1.- DE LA INFORMACIÓN</b> El acceso al Website no implica
        obligación por parte de ANTONIO MIÑANA MARÍN de comprobar la veracidad,
        exactitud, adecuación, idoneidad, exhaustividad y actualidad de la
        información suministrada a través del mismo. Los contenidos de este
        Website son de carácter general y no constituyen, en modo alguno, la
        prestación de un servicio de asesoramiento legal o scal de ningún tipo,
        por lo que dicha información resulta insuciente para la toma de
        decisiones personales o empresariales por parte del Usuario. ANTONIO
        MIÑANA MARÍN no se responsabiliza de las decisiones tomadas a partir de
        la información suministrada en el Website ni de los datos y perjuicios
        producidos en el Usuario o terceros con motivo de actuaciones que tengan
        como único fundamento la información obtenida en el Website. La
        información está sometida a posibles cambios periódicos sin previo aviso
        de su contenido por ampliación, mejora, corrección o actualización de
        los contenidos del Website.
      </p>

      <p>
        <b>5.2.- DE LA CALIDAD DEL SERVICIO DEL WEBSITE</b> El acceso al Website
        no implica la obligación por parte de ANTONIO MIÑANA MARÍN de controlar
        la ausencia de virus, gusanos o cualquier otro elemento informático
        dañino. Corresponde al Usuario, en todo caso, la disponibilidad de
        herramientas adecuadas para la detección y desinfección de programas
        informáticos dañinos. ANTONIO MIÑANA MARÍN no se responsabiliza de los
        daños producidos en los equipos informáticos de los Usuarios o de
        terceros durante la prestación del servicio del Website, ni del mal
        funcionamiento del navegador Web o el uso de versiones no actualizadas
        del mismo
      </p>

      <p>
        <b>5.3.- DE LA DISPONIBILIDAD DEL SERVICIO</b> El acceso al Website
        requiere de servicios y suministros de terceros, incluidos el transporte
        a través de redes de telecomunicaciones cuya abilidad, calidad,
        continuidad y funcionamiento no corresponde a ANTONIO MIÑANA MARÍN. Por
        consiguiente, los servicios que se proveen a través del Website pueden
        ser suspendidos, cancelados o resultar inaccesibles, con carácter previo
        o simultaneo a la prestación del servicio del Website. ANTONIO MIÑANA
        MARÍN no se responsabiliza de los daños y perjuicios de cualquier tipo
        producidos en el Usuario que traigan causa de fallos o desconexiones en
        las redes de telecomunicaciones que produzcan la suspensión, cancelación
        o interrupción del servicio del Website durante la prestación del mismo
        o con carácter previo.
      </p>

      <p>
        <b>5.4.- DE LOS CONTENIDOS Y SERVICIOS ENLAZADOS A TRAVÉS DEL PORTAL</b>
        El servicio de acceso al Website incluye dispositivos técnicos de
        enlace, directorios e incluso instrumentos de búsqueda que permiten al
        Usuario acceder a otras páginas y portales de Internet (en adelante,
        "Sitios Enlazados"). En estos casos, ANTONIO MIÑANA MARÍN actúa como
        prestador de servicios de intermediación de conformidad con el artículo
        17 de la Ley 34/2002, de 12 de julio, de Servicios de la Socidad de la
        Información y el Comercio Electrónico (LSSICE) y sólo será responsable
        de los contenidos y servicios suministrados en los Sitios Enlazados en
        la medida en que tenga conocimiento efectivo de la ilicitud y no haya
        desactivado el enlace con la debida diligencia. En el supuesto de que el
        Usuario considere que existe un Sitio Enlazado con contenidos ilícitos o
        inadecuados podrá comunicárselo a ANTONIO MIÑANA MARÍN de acuerdo con el
        procedimiento y los efectos establecidos en la cláusula 7, sin que en
        ningún caso esta comunicación conlleve la obligación de retirar el
        correspondiente enlace. En ningún caso, la existencia de Sitios
        Enlazados debe presuponer la existencia de acuerdos con los responsables
        o titulares de los mismos, ni la recomendación, promoción o identicación
        de ANTONIO MIÑANA MARÍN con las manifestaciones, contenidos o servicios
        que se proveen
      </p>

      <p>
        ANTONIO MIÑANA MARÍN no conoce los contenidos y servicios de los Sitios
        Enlazados y por tanto no se hace responsable por los daños producidos
        por la ilicitud, calidad, desactualización, indisponibilidad, error e
        inutilidad de los contenidos y/o servicios de los Sitios Enlazados ni
        por cualquier otro daño que no sea directamente imputable a ANTONIO
        MIÑANA MARÍN
      </p>

      <h4 class="display-6">6.- PROTECCIÓN DE DATOS PERSONALES</h4>

      <p>
        Para aquellos casos que se recaben, traten o almacenen datos personales,
        se hará en conformidad con la Política de Privacidad publicada en el
        Website http://www .sioactivecastellon y que puede consultar en el pie o
        footer del sitio Web
      </p>

      <h4 class="display-6">
        7.- COMUNICACIÓN DE ACTIVIDADES DE CARÁCTER ILÍCITO O INADECUADO
      </h4>

      <p>
        En el caso de que el Usuario o cualquier otro usuario de Internet
        tuviera conocimiento de que los Sitios Enlazados remiten a páginas cuyos
        contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o
        contrarios a la moral podrá ponerse en contacto con ANTONIO MIÑANA MARÍN
        indicando los siguientes extremos:
      </p>

      <ul>
        <li>
          Datos personales del comunicante: nombre, dirección, número de
          teléfono y dirección de correo electrónico;
        </li>
        <li>
          Descripción de los hechos que revelan el carácter ilícito o inadecuado
          del Sitio Enlazado;
        </li>
        <li>
          En el supuesto de violación de derechos, tales como propiedad
          intelectual e industrial, los datos personales del titular del derecho
          infringido cuando sea persona distinta del comunicante. Asimismo,
          deberá aportar el título que acredite la legitimación del titular de
          los derechos y, en su caso, el de representación para actuar por
          cuenta del titular cuando sea persona distinta del comunicante;
        </li>
        <li>
          Declaración expresa de que la información contenida en la reclamación
          es exacta.
        </li>
      </ul>

      La recepción por parte de ANTONIO MIÑANA MARÍN de la comunicación prevista
      en esta cláusula no supondrá, según lo dispuesto en la LSSI, el
      conocimiento efectivo de las actividades y/o contenidos indicados por el
      comunicante. Asimismo, deberá aportar el título que acredite la
      legitimación del titular de los derechos y, en su caso, el de
      representación para actuar por cuenta del titular cuando sea persona
      distinta del comunicante.

      <h4 class="display-6">8.- MODIFICACIÓN DEL AVISO LEGAL</h4>

      <p>
        Con el n de mejorar las prestaciones del sitio Web, FISIOACTIVE CENTRO
        FISIOTERAPIA se reserva la facultad de efectuar, en cualquier momento y
        sin necesidad de previo aviso, modicaciones y actualizaciones de la
        información contenida en el sitio Web, de la conguración y diseño de
        éste y del presente aviso legal, así como de cualesquiera otras
        condiciones particulares. Por tanto, El Usuario debe leer el Aviso Legal
        en todas y cada una de las ocasiones en que acceda al sitio Web
        http://www .sioactivecastellon.
      </p>

      <h4 class="display-6">9.- JURISDICCIÓN</h4>

      <p>
        Para cuantas cuestiones se susciten sobre la interpretación, aplicación
        y cumplimiento de este Aviso Legal, así como de las reclamaciones que
        puedan derivarse de su uso, todos las partes intervinientes se someten a
        los Jueces y Tribunales de Castellón, España renunciando de forma
        expresa a cualquier otro fuero que pudiera corresponderles.
      </p>
      <h4 class="display-6">10.- LEY APLICABLE</h4>
      <p>
        La ley aplicable en caso de disputa o conicto de interpretación de los
        términos que conforman este Aviso Legal, así como cualquier cuestión
        relacionada con los servicios del presente sitio Web, será la
        legislación española. Reservados todos los derechos de autor por las
        leyes y tratados internacionales de propiedad intelectual. Queda
        expresamente prohibida su copia, reproducción o difusión, total o
        parcial, por cualquier medio.
      </p>
    </div>
  </div>
</div>
