import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AvisoLegalComponent } from './aviso-legal/aviso-legal.component';
import { ContactoComponent } from './contacto/contacto.component';
import { CookiesComponent } from './cookies/cookies.component';
import { FisioterapiaDeportivaComponent } from './fisioterapia-deportiva/fisioterapia-deportiva.component';
import { FisioterapiaComponent } from './fisioterapia/fisioterapia.component';
import { SueloPelvicoComponent } from './suelo-pelvico/suelo-pelvico.component';
import { LinfedemaComponent } from './linfedema/linfedema.component';
import { FisioterapiaDermatofuncionalComponent } from './fisioterapia-dermatofuncional/fisioterapia-dermatofuncional.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { HomeComponent } from './home/home.component';
import { PricesComponent } from './prices/prices.component';
import { PrivacidadComponent } from './privacidad/privacidad.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'precios', component: PricesComponent },
  { path: 'galeria', component: GaleriaComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'fisioterapia', component: FisioterapiaComponent },
  { path: 'fisioterapia/deportiva', component: FisioterapiaDeportivaComponent },
  { path: 'suelo/pelvico', component: SueloPelvicoComponent },
  { path: 'linfedema', component: LinfedemaComponent },
  { path: 'fisioterapia/dermatofuncional', component: FisioterapiaDermatofuncionalComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'privacidad', component: PrivacidadComponent },
  { path: 'aviso-legal', component: AvisoLegalComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
